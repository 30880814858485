import React, { useContext, useEffect, useState } from "react";
import MasterLayout from "../masterLayout/MasterLayout";
import Breadcrumb from "../components/Breadcrumb";
import ChatMessageLayer from "../components/ChatMessageLayer";
import { GlobalInfo } from "../App";
import axios from "axios";
import useAuth from "../hook/useAuth";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Button } from "react-bootstrap";

const DocumentListShow = ({ showFiles, setShowFiles }) => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const context = useContext(GlobalInfo);
  const [url, setUrl] = useState("");
  const [open, setOpen] = useState(false);
  const tokenData = useAuth();
  const [numPages, setNumPages] = useState(1);
  const { student_id, document_category_id, document_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [documentDetails, setDocumentDetails] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);

  const [alldocuments, setAlldocuments] = useState([]);
  const getAlldocuments = async () => {
    try {
      const response = await axios.get(
        `${context.apiEndPoint}admin/student/translations/document/all/${student_id}/${document_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAlldocuments(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getDocumentDetails = async () => {
    try {
      const response = await axios.get(
        `${context.apiEndPoint}admin/student/translations/documentData/${document_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setDocumentDetails(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getOrderDetails = async () => {
    const response = await axios.get(
      `${context.apiEndPoint}admin/candidate/get-order-details/${document_id}/${student_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data.length > 0) {
      setOrderDetails(response.data[0]);
    } else {
      setOrderDetails([]);
    }
  };
  const FetchDocuments = async (noLoading = false) => {
    if (!noLoading) {
      setLoading(true);
    }
    try {
      const response = await axios.get(
        `${context.apiEndPoint}admin/student/translations/document/${student_id}/${document_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.length > 0) {
        setDocuments(response.data[0]);
        setUrl(response.data[0].filepath);
      } else {
        setDocuments([]);
        setUrl("");
      }
    } catch (error) {
      console.log(error);
    } finally {
      if (!noLoading) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getDocumentDetails();
    FetchDocuments();
    getAlldocuments();
    getOrderDetails();
  }, [document_category_id]);
  return (
    <div className="w-full">
      {showFiles && (
        // <div className="w-full h-full">
        //   <div
        //     className="border border-gray-300 rounded-md p-4 h-full flex flex-col gap-4"
        //     style={{ width: "400px" }}
        //   >
        //     <div className="flex flex-col gap-2 overflow-y-auto max-h-[35rem]">
        //       {alldocuments.length > 0 ? (
        //         alldocuments.map((document, index) => (
        //           <div key={index} style={{ width: "100%" }}>
        //             <div className="text-blue-600 bg-gray-200 p-2 rounded-md text-start flex justify-between items-center ">
        //               <div className="flex flex-col gap-2 w-8/12">
        //                 <p className="text-black text-md font-semibold  ">
        //                   {document.file_name}
        //                 </p>
        //                 <p className="text-black text-xs">
        //                   {moment(document.student_doc_modified_date).format(
        //                     "hh:mm:ss a , DD MMM, YYYY "
        //                   )}
        //                 </p>
        //               </div>
        //               <div className="w-4/12 flex items-center justify-end gap-2">
        //                 {/* <div className="cursor-pointer hover:text-blue-600 transition-all duration-300">
        //                     <BiMessageRoundedDetail size={24} />
        //                   </div> */}
        //                 <div
        //                   className="cursor-pointer hover:text-blue-600 transition-all duration-300"
        //                   onClick={() => {
        //                     window.open(
        //                       context.filesServerUrl + document.filepath,
        //                       "_blank"
        //                     );
        //                   }}
        //                 >
        //                   <Icon icon="mdi:file-outline" />
        //                 </div>
        //               </div>
        //             </div>
        //           </div>
        //         ))
        //       ) : (
        //         <div className="text-center font-bold text-black dark:text-white text-2xl mt-10 ">
        //           No documents found
        //         </div>
        //       )}
        //     </div>
        //   </div>
        // </div>
        <div
          className="w-full h-full flex justify-center items-center bg-gray-50 p-4"
          style={{ marginTop: "20px" }}
        >
          <div
            className="border border-gray-300 rounded-lg p-6 h-full flex flex-col gap-6 shadow-md w-full"
          >
            <div className="flex flex-col gap-3 overflow-y-auto max-h-[35rem]">
              {alldocuments.length > 0 ? (
                alldocuments.map((document, index) => (
                  <div
                    key={index}
                    className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-all duration-300 border border-gray-200"
                  >
                    <div className="flex items-start justify-between">
                      <div className="flex flex-col gap-2 w-8/12">
                        <p className="text-blue-600 text-lg font-semibold flex items-center gap-2">
                          <Icon
                            icon="mdi:file-outline"
                            className="text-blue-500"
                          />
                          {document.file_name}
                        </p>
                        <p className="text-gray-500 text-xs">
                          {moment(document.student_doc_modified_date).format(
                            "hh:mm:ss a, DD MMM, YYYY"
                          )}
                        </p>
                      </div>
                      <div className="w-4/12 flex items-center justify-end gap-3">
                        <div
                          className="cursor-pointer hover:text-blue-600 transition-all duration-300"
                          onClick={() => {
                            // window.open(
                            //   context.filesServerUrl + document.filepath,
                            //   "_blank"
                            // );
                            window.open(
                              context.filesServerUrl + document.filepath,
                              "_blank"
                            );
                          }}
                        >
                          <Icon
                            icon="mdi:open-in-new"
                            className="text-lg text-gray-700"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-center font-bold text-gray-600 text-xl mt-10">
                  No documents found
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentListShow;
