// import React, { useState, useContext, useEffect } from "react";
// import {
//   ColumnDef,
//   ColumnFiltersState,
//   SortingState,
//   VisibilityState,
//   flexRender,
//   getCoreRowModel,
//   getFilteredRowModel,
//   getPaginationRowModel,
//   getSortedRowModel,
//   useReactTable,
// } from "@tanstack/react-table";
// import { Icon } from "@iconify/react/dist/iconify.js";
// import axios from "axios";
// import moment from "moment";
// import { Link, useNavigate } from "react-router-dom";
// import { GlobalInfo } from "../../App";
// import "datatables.net-dt/js/dataTables.dataTables.js";
// import $ from "jquery";

// const TranslatorDocuments = ({ renderDocuments }) => {
// const [token, setToken] = useState(localStorage.getItem("token"));
// const context = useContext(GlobalInfo);
// const [sorting, setSorting] = useState([]);
// const [columnFilters, setColumnFilters] = useState([]);
// const [columnVisibility, setColumnVisibility] = useState({});
// const [currentType, setCurrentType] = useState("documents");
// const [rowSelection, setRowSelection] = useState({});
// const navigate = useNavigate();
// const [data, setData] = useState([]);
// const [loading, setLoading] = useState(true);

//   const columns = [
//     {
//       accessorKey: "fullname",
//       header: "Full Name",
//       cell: ({ row }) => (
// <Link to={`/documents/translator/student/${row.original.student_id}`}>
//   {row.original.first_name} {row.original.last_name}
// </Link>
//       ),
//     },
//     {
//       accessorKey: "document_name",
//       header: "Document Name",
//       cell: ({ row }) => (
//         <Link
//           to={
//             "/documents/candidate/translations/" +
//             row.original.student_id +
//             "/documents/" +
//             row.original.doc_cat_id +
//             "/" +
//             row.original.translation_doc_id
//           }
//         >
//           {row.original.document_name}
//         </Link>
//       ),
//     },
//     {
//       accessorKey: "number_of_pages",
//       header: "Number of pages",
//       cell: ({ row }) => (
//         <div className="text-center">{row.original.num_pages}</div>
//       ),
//     },

//     {
//       accessorKey: "net_amount",
//       header: "Net Amount",
//       cell: ({ row }) => (
//         <div className="text-center">{row.original.net_amount}</div>
//       ),
//     },
//     {
//       accessorKey: "paid_amount",
//       header: "Paid Amount",
//       cell: ({ row }) => (
//         <div className="text-center">{row.original.paid_amount}</div>
//       ),
//     },
//     {
//       accessorKey: "payment_approved_status",
//       header: "Payment Approved Status",
//       cell: ({ row }) => (
//         <div className="text-center">
//           {row.original.is_payment_approved === 1 ? (
//             <span className="bg-success-focus text-success-main px-24 py-4 rounded-pill fw-medium text-sm">
//               Approved
//             </span>
//           ) : (
//             <span className="bg-danger-focus text-danger-main px-24 py-4 rounded-pill fw-medium text-sm">
//               Pending
//             </span>
//           )}
//         </div>
//       ),
//     },

//     {
//       accessorKey: "payment_received_status",
//       header: "Payment Received Status",
//       cell: ({ row }) => (
//         <div className="text-center">
//           {row.original.payment_status === 1 ? (
//             <span className="bg-success-focus text-success-main px-24 py-4 rounded-pill fw-medium text-sm">
//               Paid
//             </span>
//           ) : (
//             <span className="bg-danger-focus text-danger-main px-24 py-4 rounded-pill fw-medium text-sm">
//               Pending
//             </span>
//           )}
//         </div>
//       ),
//     },
//     {
//       accessorKey: "document_translation_status",
//       header: "Document Translation Status",
//       cell: ({ row }) => (
//         <div className="text-center">
//           {row.original.translation_status === 0 ? (
//             <span className="bg-warning-focus text-warning-main px-24 py-4 rounded-pill fw-medium text-sm">
//               Pending
//             </span>
//           ) : (
//             <span className="bg-success-focus text-success-main px-24 py-4 rounded-pill fw-medium text-sm">
//               Completed
//             </span>
//           )}
//         </div>
//       ),
//     },

//     {
//       accessorKey: "last_updated_at",
//       header: "Last Updated At",
//       cell: ({ row }) => (
//         <div className="text-center">
//           {moment(row.original.latest_modified_at * 1000).format(
//             "DD-MM-YYYY, HH:mm"
//           )}
//         </div>
//       ),
//     },
//     {
//       id: "actions",
//       header: "Actions",
//       cell: ({ row }) => (
//         <div
//           className="cursor-pointer bg-blue-500 p-2 rounded-md"
//           onClick={() =>
//             navigate(
//               `/documents/candidate/translations/${row.original.student_id}/documents/${row.original.doc_cat_id}/${row.original.translation_doc_id}`
//             )
//           }
//         >
//           Open
//         </div>
//       ),
//     },
//   ];

//   const table = useReactTable({
//     data,
//     columns,
//     onSortingChange: setSorting,
//     onColumnFiltersChange: setColumnFilters,
//     getCoreRowModel: getCoreRowModel(),
//     getPaginationRowModel: getPaginationRowModel(),
//     getSortedRowModel: getSortedRowModel(),
//     getFilteredRowModel: getFilteredRowModel(),
//     onColumnVisibilityChange: setColumnVisibility,
//     onRowSelectionChange: setRowSelection,
//     state: {
//       sorting,
//       columnFilters,
//       columnVisibility,
//       rowSelection,
//     },
//   });

//   useEffect(() => {
//     if (data.length > 0) {
//       const tableInstance = $("#dataTable").DataTable({
//         pageLength: 10,
//       });
//       return () => {
//         tableInstance.destroy(true);
//       };
//     }
//   }, [data]);

// const getStudents = async () => {
//   try {
//     const response = await axios.get(
//       `${context.apiEndPoint}admin/translator/documents`,
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     );
//     setData(response.data);
//   } catch (error) {
//     console.error(error);
//   } finally {
//     setLoading(false);
//   }
// };

// useEffect(() => {
//   getStudents();
//   console.log("Data fetched:", data);
// }, [token]);
//   return (
//     <div className="card basic-data-table">
//       <div className="card-header">
//         <h5 className="card-title mb-0">Documents</h5>
//         <p>List of all documents requested by clients</p>
//       </div>
//       <div className="card-body overflow-x-scroll no-scrollbar">
//         <table
//           className="table bordered-table mb-0"
//           id="dataTable"
//           data-page-length={10}
//         >
//           <thead>
//             {table.getHeaderGroups().map((headerGroup) => (
//               <tr scope="col" key={headerGroup.id}>
//                 {headerGroup.headers.map((header) => (
//                   <th
//                     style={{
//                       width: "auto",
//                       minWidth: "130px",
//                       maxWidth: "90px",
//                     }}
//                     key={header.id}
//                   >
//                     {header.isPlaceholder
//                       ? null
//                       : flexRender(
//                           header.column.columnDef.header,
//                           header.getContext()
//                         )}
//                   </th>
//                 ))}
//               </tr>
//             ))}
//           </thead>
//           <tbody>
//             {table.getRowModel().rows?.length
//               ? table.getRowModel().rows.map((row) => (
//                   <tr key={row.id}>
//                     {row.getVisibleCells().map((cell, cellIndex) => {
//                       if (cellIndex === 0) {
//                         return (
//                           <td key={cell.id}>
//                             <div className="form-check style-check d-flex align-items-center">
//                               <label
//                                 className="form-check-label"
//                                 style={{ textTransform: "capitalize" }}
//                               >
//                                 {flexRender(
//                                   cell.column.columnDef.cell,
//                                   cell.getContext()
//                                 )}
//                               </label>
//                             </div>
//                           </td>
//                         );
//                       }
//                       if (cellIndex === 1) {
//                         return (
//                           <td
//                             key={cell.id}
//                             style={{ textTransform: "capitalize" }}
//                           >
//                             <Link to="#" className="text-warning-600">
//                               {flexRender(
//                                 cell.column.columnDef.cell,
//                                 cell.getContext()
//                               )}
//                             </Link>
//                           </td>
//                         );
//                       }
//                       if (cellIndex === 2) {
//                         return (
//                           <td key={cell.id}>
//                             <div className="form-check style-check d-flex align-items-center">
//                               <label className="form-check-label">
//                                 {flexRender(
//                                   cell.column.columnDef.cell,
//                                   cell.getContext()
//                                 )}
//                               </label>
//                             </div>
//                           </td>
//                         );
//                       }

//                       if (cellIndex === 3) {
//                         return (
//                           <td key={cell.id}>
//                             <div className="d-flex align-items-center">
//                               <h6 className="text-md mb-0 fw-medium flex-grow-1">
//                                 {cell.getValue()}
//                               </h6>
//                             </div>
//                           </td>
//                         );
//                       }
//                       if (cellIndex === 4) {
//                         return <td key={cell.id}>{cell.getValue()}</td>;
//                       }
//                       if (cellIndex === 5) {
//                         return (
//                           <td key={cell.id}>
//                             {flexRender(
//                               cell.column.columnDef.cell,
//                               cell.getContext()
//                             )}
//                           </td>
//                         );
//                       }
//                       if (cellIndex === 6) {
//                         return (
//                           <td key={cell.id}>
//                             {flexRender(
//                               cell.column.columnDef.cell,
//                               cell.getContext()
//                             )}
//                           </td>
//                         );
//                       }
//                       if (cellIndex === 7) {
//                         return (
//                           <td key={cell.id}>
//                             {flexRender(
//                               cell.column.columnDef.cell,
//                               cell.getContext()
//                             )}
//                           </td>
//                         );
//                       }
//                       if (cellIndex === 8) {
//                         return (
//                           <td className="text-md mb-0 fw-medium flex-grow-1">
//                             {moment(cell.getValue()).format(
//                               "DD-MM-YYYY, HH:mm"
//                             )}
//                           </td>
//                         );
//                       }
//                       if (cellIndex === 9) {
//                         return (
//                           <td key={cell.id}>
// <Link
//   to={`/documents/translator/student/${row.original.student_id}`}
//   className="bg-primary-light text-primary-600 px-24 py-4 rounded-pill fw-medium text-sm"
// >
//   Open
// </Link>
//                           </td>
//                         );
//                       }
//                     })}
//                   </tr>
//                 ))
//               : null}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default TranslatorDocuments;
import React, { useState, useContext, useEffect, useRef } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import axios from "axios";
import { Link } from "react-router-dom";
import { GlobalInfo } from "../../App";
import "datatables.net-dt/js/dataTables.dataTables.js";
import $ from "jquery";
import moment from "moment";
import { ExternalLinkIcon } from "@radix-ui/react-icons";
import { FiFileText, FiClock, FiMail } from "react-icons/fi";
import { MdVerified } from "react-icons/md";
import { FaTruck } from "react-icons/fa";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import { Tooltip } from "bootstrap/dist/js/bootstrap.bundle.min.js";

const TableDataLayer = () => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const context = useContext(GlobalInfo);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showDatePicker, setShowDatePicker] = useState(null);
  const [modalInfo, setModalInfo] = useState({
    show: false,
    action: null,
    index: null
  });

  const [student_id, setStudentId] = useState(null);
  const tableRef = useRef(null);
  const searchValueRef = useRef('');

  // Fetch data from the server
  const getStudents = async () => {
    try {
      const response = await axios.get(
        `${context.apiEndPoint}admin/translator/documents`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setData(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    getStudents();
  }, [token]);

  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]'
    );
    const tooltipList = [...tooltipTriggerList].map((tooltipTriggerEl) => {
      return new Tooltip(tooltipTriggerEl);
    });
    return () => {
      tooltipList.forEach((tooltip) => tooltip.dispose());
    };
  }, [data]);

  useEffect(() => {
    if (!loading) {
      // Store current search value if table exists
      if ($.fn.DataTable.isDataTable('#dataTable')) {
        searchValueRef.current = $('#dataTable').DataTable().search();
        $('#dataTable').DataTable().destroy();
      }

      // Initialize new table if there is data
      if (data.length > 0) {
        const table = $('#dataTable').DataTable({
          pageLength: 10,
          initComplete: function() {
            // Restore search value after initialization
            if (searchValueRef.current) {
              this.api().search(searchValueRef.current).draw();
            }
          }
        });

        tableRef.current = table;

        // Save search value when user types
        $('#dataTable').on('search.dt', function() {
          searchValueRef.current = table.search();
        });

        return () => {
          if (tableRef.current) {
            tableRef.current.destroy();
          }
        };
      }
    }
  }, [data, loading]);

  const DatePicker = ({ id, placeholder, index, disabled, student }) => {
    const datePickerRef = useRef(null);

    useEffect(() => {
      flatpickr(datePickerRef.current, {
        enableTime: false,
        dateFormat: "d/m/Y",
        minDate: "today",
        onChange: (selectedDates, dateStr, instance) =>
          handleDeadLinedate(selectedDates, index, student)
      });
    }, []);

    return (
      <input
        ref={datePickerRef}
        id={id}
        type="text"
        className="w-0"
        placeholder={placeholder}
        disabled={disabled}
      />
    );
  };

  const handleDeadLinedate = (selectedDates, index, student = null) => {
    handleActions("deadline", index, selectedDates, student);
  };

  const handleModalConfirm = () => {
    console.log(
      "Confirmed action:",
      modalInfo.action,
      "Index:",
      modalInfo.index
    );
    handleActions(modalInfo.action, modalInfo.index);
    setModalInfo({ show: false, action: null, index: null });
  };

  const getModalColors = (action) => {
    switch (action) {
      case "drafted":
        return "border-yellow-600 bg-yellow-600";
      case "certified":
        return "border-purple-600 bg-purple-600";
      case "dispatched":
        return "border-red-600 bg-red-600";
      default:
        return "border-amber-500 bg-amber-500";
    }
  };

  const getModalTextColor = (action) => {
    switch (action) {
      case "drafted":
        return "text-yellow-600";
      case "certified":
        return "text-purple-600";
      case "dispatched":
        return "text-red-600";
      default:
        return "text-amber-500";
    }
  };

  const handleActions = async (
    action,
    index,
    selectedDates,
    student = null
  ) => {
    console.log("Action:", action, "Index:", index);
    console.log("Student ID:", student_id, student);
    if (student) {
      setStudentId(student);
    }

    try {
      const data = {
        action,
        translation_doc_id: index,
        student_id: student_id || student
      };
      if (action == "deadline") {
        data.deadline_date = moment(selectedDates[0]).format("YYYY-MM-DD");
      }
      const response = await axios.post(
        `${context.apiEndPoint}admin/translator/documents/actions/`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      console.log(response.data);
      setStudentId(null);
      getStudents();

      // if (
      //   action == "drafted" ||
      //   action == "certified" ||
      //   action == "dispatched"
      // ) {
      //   setdata(data);
      // }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="card basic-data-table">
      <div className="card-header">
        <h5 className="card-title mb-0">Default Data Tables</h5>
      </div>
      <div className="card-body overflow-x-scroll no-scrollbar">
        <table
          className="table bordered-table mb-0"
          id="dataTable"
          data-page-length={10}
        >
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Document Name</th>
              <th>Number of Pages</th>
              <th>Price</th>
              <th>Translation Price</th>
              <th>Translation Status</th>
              <th>Payment Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {!loading && data.length > 0 ? (
              data.map((item, index) => (
                <tr key={index}>
                  <td>
                    <Link
                      target="_blank"
                      to={`/documents/translator/student/${item.student_id}`}
                    >
                      <div className="text-blue-500 font-semibold flex items-center">
                        {item.first_name} {item.last_name}
                        <div className="bg-primary-light text-primary-600 rounded-full p-4">
                          <ExternalLinkIcon className="text-semibold " />
                        </div>
                      </div>
                    </Link>
                  </td>
                  <td>
                    <Link
                      className="text-blue-600"
                      to={
                        "/documents/candidate/translations/" +
                        item.student_id +
                        "/documents/" +
                        item.doc_cat_id +
                        "/" +
                        item.translation_doc_id
                      }
                    >
                      <p className="font-bold ">{item.document_name}</p>
                    </Link>
                  </td>
                  <td>
                    <div className="text-center">{item.num_pages}</div>
                  </td>
                  <td>
                    <div className="text-center">{item.net_amount}</div>
                  </td>

                  <td>
                    <div>
                      {item.is_payment_approved === 0 && (
                        <span className="bg-yellow-100 text-yellow-600 px-24 py-4 rounded-pill fw-medium text-sm">
                          Awaits Price Approval
                        </span>
                      )}
                      {item.is_payment_approved === 1 && (
                        <span className="bg-blue-100 text-blue-600 px-24 py-4 rounded-pill fw-medium text-sm">
                          Translator Accepted
                        </span>
                      )}
                      {item.is_payment_approved === 2 && (
                        <span className="bg-purple-100 text-purple-600 px-24 py-4 rounded-pill fw-medium text-sm">
                          Translator Adjusted
                        </span>
                      )}
                      {item.is_payment_approved === 3 && (
                        <span className="bg-green-100 text-green-600 px-24 py-4 rounded-pill fw-medium text-sm">
                          Student Approved
                        </span>
                      )}

                      {item.is_payment_approved === 4 && (
                        <span className="bg-red-100 text-red-600 px-24 py-4 rounded-pill fw-medium text-sm">
                          Translator Rejected
                        </span>
                      )}
                      {item.is_payment_approved === 5 && (
                        <span className="bg-red-100 text-red-600 px-24 py-4 rounded-pill fw-medium text-sm">
                          Student Rejected
                        </span>
                      )}
                    </div>
                  </td>
                  <td>
                    <div>
                      {item.translation_status == 0 && (
                        <span className="bg-red-500 text-white px-3 py-1 rounded-full font-medium text-sm">
                          Not Started
                        </span>
                      )}
                      {item.translation_status == 1 && (
                        <span className="bg-yellow-500 text-white px-3 py-1 rounded-full font-medium text-sm">
                          D/L :{" "}
                          {moment(item.deadline_date).format("DD MMM, YYYY")}
                        </span>
                      )}
                      {item.translation_status == 2 && (
                        <span className="bg-blue-500 text-white px-3 py-1 rounded-full font-medium text-sm">
                          Draft uploaded
                        </span>
                      )}
                      {item.translation_status == 3 && (
                        <span className="bg-violet-500 text-white px-3 py-1 rounded-full font-medium text-sm">
                          Student approved
                        </span>
                      )}
                      {item.translation_status == 4 && (
                        <span className="bg-green-500 text-white px-3 py-1 rounded-full font-medium text-sm">
                          Certified
                        </span>
                      )}
                      {item.translation_status == 5 && (
                        <span className="bg-cyan-500 text-white px-3 py-1 rounded-full font-medium text-sm">
                          Requested Dispatched
                        </span>
                      )}
                      {item.translation_status == 6 && (
                        <span className="bg-purple-500 text-purple-100 px-3 py-1 rounded-full font-medium text-sm">
                          Dispatched
                        </span>
                      )}
                    </div>
                  </td>
                  <td>
                    <div>
                      {item.payment_status == 0 && (
                        <span className="bg-warning-focus text-warning-main px-24 py-4 rounded-pill fw-medium text-sm">
                          Pending
                        </span>
                      )}
                      {item.payment_status == 1 && (
                        <span className="bg-success-focus text-success-main px-24 py-4 rounded-pill fw-medium text-sm">
                          Paid
                        </span>
                      )}
                      {item.payment_status == 2 && (
                        <span className="bg-danger-focus text-danger-main px-24 py-4 rounded-pill fw-medium text-sm">
                          Overdue
                        </span>
                      )}
                      {item.payment_status == 3 && (
                        <span className="bg-neutral-focus text-neutral-main px-24 py-4 rounded-pill fw-medium text-sm">
                          N/A
                        </span>
                      )}
                    </div>
                  </td>

                  <td>
                    <div className="flex gap-3">
                      <div
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Open document"
                      >
                        <Link
                          to={
                            "/documents/candidate/translations/" +
                            item.student_id +
                            "/documents/" +
                            item.doc_cat_id +
                            "/" +
                            item.translation_doc_id
                          }
                          className="flex items-center bg-blue-600 text-white px-4 py-4 rounded-lg font-medium text-base"
                          style={{
                            textDecoration: "none"
                          }}
                        >
                          <FiFileText className="size-6 text-white" />
                        </Link>
                      </div>

                      <div className="relative flex items-center">
                        <label
                          htmlFor={`deadline-${index}`}
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Set deadline"
                          className={`flex items-center bg-green-600 ${
                            !(item?.payment_status == 1)
                              ? "opacity-50 !cursor-not-allowed"
                              : ""
                          } text-white px-4 py-4 rounded-lg font-medium text-base`}
                          onClick={() => setShowDatePicker(index)}
                        >
                          <DatePicker
                            disabled={!(item?.payment_status == 1)}
                            className=" absolute top-0 left-0 "
                            id={`deadline-${index}`}
                            index={item?.translation_doc_id}
                            student={item?.student_id}
                          />
                          <FiClock className="size-6 text-white" />
                        </label>
                      </div>

                      <button
                        disabled={
                          !(
                            item?.payment_status == 1 &&
                            item?.translation_status >= 1
                          )
                        }
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Mark as Drafted"
                        className={`flex items-center ${
                          !(
                            item?.payment_status == 1 &&
                            item?.translation_status >= 1
                          )
                            ? "opacity-50 !cursor-not-allowed"
                            : ""
                        } ${getModalColors(
                          "drafted"
                        )} text-white px-4 py-4 rounded-lg font-medium text-base`}
                        onClick={() => {
                          setModalInfo({
                            show: true,
                            action: "drafted",
                            index: item.translation_doc_id
                          });
                          setStudentId(item.student_id);
                        }}
                      >
                        <FiMail className="size-6 text-white" />
                      </button>
                      <button
                        disabled={
                          !(
                            item?.payment_status == 1 &&
                            item?.translation_status >= 3
                          )
                        }
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Mark as Certified"
                        className={`flex items-center ${
                          !(
                            item?.payment_status == 1 &&
                            item?.translation_status >= 3
                          )
                            ? "opacity-50 !cursor-not-allowed"
                            : ""
                        } ${getModalColors(
                          "certified"
                        )} text-white px-4 py-4 rounded-lg font-medium text-base`}
                        onClick={() => {
                          setModalInfo({
                            show: true,
                            action: "certified",
                            index: item.translation_doc_id
                          });
                          setStudentId(item.student_id);
                        }}
                      >
                        <MdVerified className="size-6 text-white" />
                      </button>
                      <button
                        disabled={
                          !(
                            item?.payment_status == 1 &&
                            item?.translation_status >= 5
                          )
                        }
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Mark as Dispatched"
                        className={`flex items-center ${
                          !(
                            item?.payment_status == 1 &&
                            item?.translation_status >= 5
                          )
                            ? "opacity-50 !cursor-not-allowed"
                            : ""
                        } ${getModalColors(
                          "dispatched"
                        )} text-white px-4 py-4 rounded-lg font-medium text-base`}
                        onClick={() => {
                          setModalInfo({
                            show: true,
                            action: "dispatched",
                            index: item.translation_doc_id
                          });
                          setStudentId(item.student_id);
                        }}
                      >
                        <FaTruck className="size-6 text-white" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="10" className="text-center">
                  {loading ? "Loading data..." : "No data available"}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {modalInfo.show && (
        <div
          className="fixed inset-0 flex items-center justify-center z-50 gap-8"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div
            className={`bg-white rounded-lg shadow-2xl border-t-4 ${getModalColors(
              modalInfo.action
            )} animate-fade-in-down p-8`}
          >
            <div className="p-8 flex justify-between items-center border-b">
              <div className="flex items-center gap-4">
                <svg
                  className={`w-10 h-10 ${getModalColors(
                    modalInfo.action
                  )} text-white`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
                <h5 className="text-2xl font-semibold text-gray-800">
                  Confirm Action
                </h5>
              </div>
              <button
                className="text-gray-500 hover:text-gray-700"
                onClick={() =>
                  setModalInfo({ show: false, action: null, index: null })
                }
              >
                <svg
                  className="w-8 h-8"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
            <div className="p-24">
              <p className="text-gray-600 text-center text-xl py-24">
                Are you sure you want to mark this document as{" "}
                <span
                  className={`font-semibold text-xl px-2 py-1 rounded ${getModalTextColor(
                    modalInfo.action
                  )}`}
                >
                  {modalInfo.action}
                </span>
                ?
              </p>
            </div>
            <div className="bg-gray-50 px-8 py-6 flex justify-between items-center gap-6 rounded-b-lg gap-8">
              <button
                className="px-8 py-8 text-gray-600 bg-gray-200 hover:bg-gray-300 rounded-lg transition-colors text-lg"
                onClick={() =>
                  setModalInfo({ show: false, action: null, index: null })
                }
              >
                Cancel
              </button>
              <button
                className={`${getModalColors(
                  modalInfo.action
                )} text-white p-8 rounded-lg transition-colors text-lg hover:opacity-90`}
                onClick={handleModalConfirm}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableDataLayer;
