import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { GlobalInfo } from "../App";
import axios from "axios";
import { io } from "socket.io-client";
import useAuth from "../hook/useAuth";
import DocumentListShow from "../pages/DocumentListShow";
import moment from "moment";

const ChatMessageLayer = ({ setShowFiles, showFiles }) => {
  const fileInputRef = useRef(null);
  const { student_id, document_category_id, document_id } = useParams();
  const [token, setToken] = useState(localStorage.getItem("token"));
  const tokenData = useAuth();

  const { client_id, first_name, last_name } = useAuth();
  const [messages, setMessages] = useState([]);

  const [socket, setSocket] = useState(null);

  const [currentMessage, setCurrentMessage] = useState("");

  const context = useContext(GlobalInfo);

  useEffect(() => {
    const socket11 = io(context.apiEndPoint);
    setSocket(socket11);
    socket11.emit(
      "joinGroupChat",
      "indephysio" + document_id + student_id + "room"
    );

    socket11.on("message", (message) => {
      // console.log("message:", message);

      setMessages((prevMessages) => [...prevMessages, message]);
      setTimeout(() => {
        scrollToBottom();
      }, 100);
    });

    getMessages();
  }, []);

  const getMessages = async () => {
    try {
      const response = await axios.get(
        `${context.apiEndPoint}admin/student/translations/chat/${student_id}/${document_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setMessages(response.data);
      setTimeout(() => {
        scrollToBottom();
      }, 100);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSendMessage = async () => {
    try {
      const newMessage = {
        translation_doc_id: document_id,
        student_id: student_id,
        client_id: client_id,
        is_student: 0,
        message: maskSensitiveInfo(currentMessage),
        is_file: 0,
        filepath: "",
        message_timestamp: Date.now().toString().slice(0, -3),
        admin_first_name: tokenData.first_name,
        admin_last_name: tokenData.last_name,
        student_first_name: null,
        student_last_name: null
      };

      socket.emit("messageToGroupChat", {
        roomName: "indephysio" + document_id + student_id + "room",
        message: newMessage
      });

      //   const response = await axios.post(
      //     `${context.apiEndPoint}admin/student/translations/chat`,
      //     {
      //       message: currentMessage,
      //       student_id: student_id,
      //       document_id: document_id,
      //       is_student: 0
      //     },
      //     {
      //       headers: {
      //         Authorization: `Bearer ${token}`
      //       }
      //     }
      //   );
      //   console.log("====================================");
      //   console.log(response.data);
      //   console.log("====================================");
      setCurrentMessage("");
      scrollToBottom();
    } catch (error) {
      console.log(error);
    }
  };

  function maskSensitiveInfo(text) {
    // Regular expression for URLs
    const urlPattern = /https?:\/\/[^\s]+/gi;

    // Regular expression for email addresses
    const emailPattern =
      /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/gi;

    // Regular expression for phone numbers (generic pattern)
    const phonePattern = /\b\d{8,15}\b/g; // Adjust this pattern based on specific phone number formats

    // Replace URLs, emails, and phone numbers with ********
    return text
      .replace(urlPattern, "********")
      .replace(emailPattern, "********")
      .replace(phonePattern, "********");
  }

  const scrollToBottom = () => {
    const chatContainer = document.getElementById("chat-container");
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight + 400;
    }
  };

  //   const handleFileChange = async (e) => {
  //     const file = e.target.files[0];

  //     // const formData = new FormData();
  //     let name = file.fileName;
  //     file.name = name;
  //     const formData = new FormData();
  //     // formData.append('file', file);
  //     formData.append("file", file);
  //     const response = await axios.post(
  //       `${context.apiEndPoint}upload/image`,
  //       formData
  //     );
  //     console.log(response.data);
  //     updateFilePath(response.data.filepath, file.name);
  //     setCurrentMessage("");
  //     scrollToBottom();
  //   };
  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await axios.post(
        `${context.apiEndPoint}upload/image`,
        formData
      );
      console.log(response.data);
      updateFilePath(response.data.filepath, file.name);
      setCurrentMessage("");
      scrollToBottom();
    } catch (error) {
      console.error("File upload failed:", error);
    }
  };

  const updateFilePath = async (filepath, filename) => {
    const newMessage = {
      translation_doc_id: document_id,
      student_id: student_id,
      client_id: tokenData.client_id,
      is_student: 0,
      message: filename,
      is_file: 1,
      filepath: filepath,
      message_timestamp: Date.now().toString().slice(0, -3),
      admin_first_name: tokenData.first_name,
      admin_last_name: tokenData.last_name,
      student_first_name: null,
      student_last_name: null
    };

    socket.emit("messageToGroupChatDoc", {
      roomName: "indephysio" + document_id + student_id + "room",
      message: newMessage
    });
  };

  const messageListRef = useRef(null);

  // Scroll to the bottom whenever a new message is added
  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className="chat-wrapper " style={{ height: "fit-content" }}>
      <div className="chat-main card" style={{ width: "100%" }}>
        <div className="chat-sidebar-single">
          {/* <div className="img"> */}
          <div
            style={{
              width: "40px",
              height: "40px",
              backgroundColor: "var(--brand)",
              borderRadius: "50px",
              display: "flex",
              justifyContent: "center",
              alignSelf: "center"
            }}
          >
            <p
              style={{
                color: "white",
                marginTop: "6px",
                textTransform: "uppercase"
              }}
            >
              {messages.length > 0
                ? messages[0]?.is_student !== 0
                  ? messages[0]?.student_first_name?.charAt(0)
                  : "P"
                : "P"}
            </p>
            {/* </div> */}
          </div>
          {messages.length > 0 && (
            <div className="info" key={messages[0]?.id}>
              <h6
                className="text-md mb-0"
                style={{ textTransform: "uppercase" }}
              >
                {messages[0]?.is_student !== 0
                  ? `${messages[0]?.student_first_name} ${messages[0]?.student_last_name}`
                  : null}
              </h6>
            </div>
          )}
          <div className="action d-inline-flex align-items-center gap-3"></div>
        </div>
        {/* <div className="chat-message-list">
          {messages.length === 0 ? (
            <div className="flex items-center justify-center h-full">
              <span className="text-gray-500">No messages yet</span>
            </div>
          ) : (
            messages.map((message, index) => (
              <div
                className={`chat-single-message ${
                  message.is_student === 0 && message.client_id === client_id
                    ? "right"
                    : "left"
                }`}
                key={message.message_timestamp}
              >
                {message.is_file === 0 ? (
                  <div className="chat-message-content">
                    <p className="mb-3">{message.message}</p>
                    <p className="chat-time mb-0">
                      <span>
                        {" "}
                        {moment(
                          parseInt(message.message_timestamp) * 1000
                        ).format("hh:mm:ss a, DD MMM, YYYY")}
                      </span>
                    </p>
                  </div>
                ) : (
                  <div className="chat-message-content">
                    <p className="mb-3">{message.message}</p>
                    <p className="chat-time mb-0">
                      <span>
                        {moment(
                          parseInt(message.message_timestamp) * 1000
                        ).format("hh:mm:ss a, DD MMM, YYYY")}
                      </span>
                    </p>
                    <div
                      style={{
                        backgroundColor: "teal",
                        color: "white",
                        padding: "8px",
                        borderRadius: "6px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "4px",
                        cursor: "pointer",
                        fontSize: "12px",
                      }}
                      onClick={() =>
                        window.open(
                          context.filesServerUrl + message.filepath,
                          "_blank"
                        )
                      }
                    >
                      <Icon icon="mdi:file-outline" />
                      Open
                    </div>
                  </div>
                )}
              </div>
            ))
          )}
        </div> */}
        <div
          className="chat-message-list h-full overflow-y-auto"
          ref={messageListRef}
        >
          {messages.length === 0 ? (
            <div className="flex items-center justify-center h-full">
              <span className="text-gray-500">No messages yet</span>
            </div>
          ) : (
            messages.map((message, index) => (
              <div
                className={`chat-single-message ${
                  message.is_student === 0 && message.client_id === client_id
                    ? "right"
                    : "left"
                }`}
                key={message.message_timestamp}
              >
                {message.is_file === 0 ? (
                  <div className="chat-message-content">
                    <p className="mb-3">{message.message}</p>
                    <p className="chat-time mb-0">
                      <span>
                        {moment(
                          parseInt(message.message_timestamp) * 1000
                        ).format("hh:mm:ss a, DD MMM, YYYY")}
                      </span>
                    </p>
                  </div>
                ) : (
                  <div className="chat-message-content">
                    <p className="mb-3">{message.message}</p>
                    <p className="chat-time mb-0">
                      <span>
                        {moment(
                          parseInt(message.message_timestamp) * 1000
                        ).format("hh:mm:ss a, DD MMM, YYYY")}
                      </span>
                    </p>
                    <div
                      style={{
                        backgroundColor: "teal",
                        color: "white",
                        padding: "8px",
                        borderRadius: "6px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "4px",
                        cursor: "pointer",
                        fontSize: "12px"
                      }}
                      onClick={() =>
                        window.open(
                          context.filesServerUrl + message.filepath,
                          "_blank"
                        )
                      }
                    >
                      <Icon icon="mdi:file-outline" />
                      Open
                    </div>
                  </div>
                )}
              </div>
            ))
          )}
        </div>

        <div className="chat-message-box">
          <input
            type="text"
            placeholder="Type your message..."
            name="chatMessage"
            value={currentMessage}
            onChange={(e) => setCurrentMessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSendMessage();
              }
            }}
          />
          <div className="chat-message-box-action">
            <button
              className={`text-teal-600 p-2 rounded-md `}
              onClick={() => setShowFiles(!showFiles)}
            >
              {showFiles ? (
                <Icon icon="ph:folder-open" style={{ fontSize: "20px" }} />
              ) : (
                <Icon icon="ph:folder-open" style={{ fontSize: "20px" }} />
              )}
            </button>

            <button
              onClick={() => fileInputRef.current.click()}
              className="text-teal-600 hover:bg-teal-600 hover:text-white transition-all duration-300 p-2 rounded-md cursor-pointer flex items-center gap-2 focus:outline-teal-600 focus:border-teal-600 focus:bg-teal-600 focus:text-white"
            >
              <Icon icon="ph:paperclip" style={{ fontSize: "20px" }} />
            </button>

            {/* Hidden file input */}
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              style={{ display: "none" }}
              id="uploadattachment"
            />
            <button
              disabled={currentMessage.length === 0}
              onClick={handleSendMessage}
              className="btn btn-sm btn-primary-600 radius-8 d-inline-flex align-items-center gap-1"
            >
              Send
              <Icon icon="f7:paperplane" />
            </button>
          </div>
        </div>
      </div>
      <DocumentListShow showFiles={showFiles} setShowFiles={setShowFiles} />
    </div>
  );
};

export default ChatMessageLayer;
