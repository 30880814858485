import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { GlobalInfo } from "../App";
import { useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Document, Page, pdfjs } from "react-pdf";
import useAuth from "../hook/useAuth";
import { Icon } from "@iconify/react/dist/iconify.js";
import { io } from "socket.io-client";
import { ClientContext } from "../hook/ClientContext";
import moment from "moment";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetDescription
} from "../components/ui/Sheet";
import PriceDealTranslation from "./PriceDealTranslation";

const TranslateDocument = () => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const { clientDetails } = useContext(ClientContext);
  const context = useContext(GlobalInfo);
  const [url, setUrl] = useState("");
  const [showFiles, setShowFiles] = useState(false);
  const [side, setSide] = useState("bottom");
  const [open, setOpen] = useState(false);
  const [socket, setSocket] = useState(null);
  const tokenData = useAuth();
  const [numPages, setNumPages] = useState(1);
  const [isChecked, setIsChecked] = useState(false);
  // const { document_category_id, document_id } = useParams();

  const [pageNumber, setPageNumber] = useState(1);

  const { student_id, document_category_id, document_id } = useParams();
  // const { student_id, doc_cat_id, translation_doc_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [documents, setDocuments] = useState([]);
  const [documentDetails, setDocumentDetails] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);

  const [alldocuments, setAlldocuments] = useState([]);
  const [translationData, setTranslationData] = useState([]);

  const handleFileChange = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      console.log(file);

      handleFileUpload(file);
    }
  };
  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    "pdfjs-dist/build/pdf.worker.min.js",
    import.meta.url
  ).toString();

  const handleFileChangeTranslated = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      console.log(file);

      handleFileUploadTranslated(file);
    }
  };

  const handleFileUploadTranslated = async (file) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    const response = await axios.post(
      `${context.apiEndPoint}upload/image`,
      formData
    );
    setUrl(response.data.filepath);
    setIsChecked(true);
    updateFilePathTranslated(response.data.filepath, file.name);
    setLoading(false);
  };

  const updateFilePath = async (filepath, filename) => {
    try {
      const response = await axios.post(
        `${context.apiEndPoint}admin/student/translations/update/document`,
        {
          filepath: filepath,
          filename: filename,
          document_id: document_id,
          student_id: student_id,
          document_category_id: document_category_id
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      const newMessage = {
        translation_doc_id: document_id,
        student_id: student_id,
        client_id: tokenData.client_id,
        is_student: 0,
        message: filename,
        is_file: 1,
        filepath: filepath,
        message_timestamp: Date.now().toString().slice(0, -3),
        admin_first_name: tokenData.first_name,
        admin_last_name: tokenData.last_name,
        student_first_name: null,
        student_last_name: null
      };

      socket.emit("messageToGroupChatDoc", {
        roomName: "indephysio" + document_id + student_id + "room",
        message: newMessage
      });
    } catch (error) {
      console.log(error);
    }
  };

  const updateFilePathTranslated = async (filepath, filename) => {
    try {
      const response = await axios.post(
        `${context.apiEndPoint}admin/student/translations/update/document/translated`,
        {
          filepath: filepath,
          filename: filename,
          document_id: document_id,
          student_id: student_id,
          document_category_id: document_category_id
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      const newMessage = {
        translation_doc_id: document_id,
        student_id: student_id,
        client_id: tokenData.client_id,
        is_student: 0,
        message: filename,
        is_file: 1,
        filepath: filepath,
        message_timestamp: Date.now().toString().slice(0, -3),
        admin_first_name: tokenData.first_name,
        admin_last_name: tokenData.last_name,
        student_first_name: null,
        student_last_name: null
      };

      socket.emit("messageToGroupChatDoc", {
        roomName: "indephysio" + document_id + student_id + "room",
        message: newMessage
      });
      getAlldocuments();
    } catch (error) {
      console.log(error);
    }
  };
  const handleFileUpload = async (file) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    const response = await axios.post(
      `${context.apiEndPoint}upload/image`,
      formData
    );
    setUrl(response.data.filepath);
    updateFilePath(response.data.filepath, file.name);
    setLoading(false);
  };

  const getAlldocuments = async () => {
    try {
      const response = await axios.get(
        `${context.apiEndPoint}admin/student/translations/document/all/${student_id}/${document_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setAlldocuments(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckboxChange = (checked) => {
    setLoading(true);
    if (checked) {
      setUrl(documents.translated_file_path);
    } else {
      setUrl(documents.filepath);
    }
    setIsChecked(!isChecked);
    setLoading(false);
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setLoading(false);
  }

  const getDocumentDetails = async () => {
    try {
      const response = await axios.get(
        `${context.apiEndPoint}admin/student/translations/documentData/${document_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setDocumentDetails(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getOrderDetails = async () => {
    const response = await axios.get(
      `${context.apiEndPoint}admin/candidate/get-order-details/${document_id}/${student_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    if (response.data.length > 0) {
      setOrderDetails(response.data[0]);
    } else {
      setOrderDetails([]);
    }
  };
  const FetchDocuments = async (noLoading = false) => {
    if (!noLoading) {
      setLoading(true);
    }
    try {
      const response = await axios.get(
        `${context.apiEndPoint}admin/student/translations/document/${student_id}/${document_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (response.data.length > 0) {
        setDocuments(response.data[0]);
        setUrl(response.data[0].filepath);
        setTranslationData(response.data[0]);
      } else {
        setDocuments([]);
        setUrl("");
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDocumentDetails();
    FetchDocuments();
    getAlldocuments();
    getOrderDetails();
  }, [document_id]);

  let canvasRef = useRef(null);

  const onPageRenderSuccess = () => {
    if (canvasRef.current) {
      canvasRef.current.style.width = "350px";
      canvasRef.current.style.height = "400px";
    }
  };

  return (
    <div className="container mx-auto p-2 lg:p-4 text-black dark:text-white wrap-content">
      <div className="flex px-2 lg:px-10 justify-between items-center my-4">
        <div>
          <h1 className="text-2xl font-bold mb-4"> Document Details</h1>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row gap-4 w-full px-2 lg:px-10 ">
        <div
          className="h-full rounded-md flex justify-center items-center w-full lg:w-1/3 "
          style={{ width: "100%" }}
        >
          <div className="w-full h-full   rounded-md min-h-[30rem] flex flex-col gap-4 justify-between items-between p-2">
            <div className="flex flex-row gap-2 items-center justify-start">
              <p className=" font-bold mb-4">
                {documentDetails.document_name && (
                  <span className="text-blue-600 pr-2">
                    {" "}
                    {documentDetails.document_name}
                  </span>
                )}
                {documentDetails.document_name && "/"}
                {documentDetails.document_description && (
                  <span className=" pl-2">
                    {documentDetails.document_description}
                  </span>
                )}
              </p>
            </div>
            <div className="flex py-2  gap-2 w-full justify-center items-center">
              <div>
                <label className="themeSwitcherTwo relative inline-flex cursor-pointer select-none items-center">
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={() => handleCheckboxChange(!isChecked)}
                    className="sr-only"
                  />
                  <span className="label flex items-center text-sm font-medium text-black">
                    Original
                  </span>
                  <span
                    className={`slider mx-4 flex h-8 w-[60px] items-center rounded-full p-1 duration-200 ${
                      isChecked ? "bg-teal-600" : "bg-[#CCCCCE]"
                    }`}
                  >
                    <span
                      className={`dot h-6 w-6 rounded-full bg-white duration-200 ${
                        isChecked ? "translate-x-[28px]" : ""
                      }`}
                    ></span>
                  </span>
                  <span className="label flex items-center text-sm font-medium text-black">
                    Translated
                  </span>
                </label>
              </div>
            </div>
            {loading ? (
              <div className="border border-dashed border-gray-400 rounded-md p-4 flex-1 flex justify-center flex-col items-center">
                <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-600"></div>
                <p className="mt-4">Loading document...</p>
              </div>
            ) : url ? (
              <div className=" border border-dashed border-gray-400 rounded-md p-4 flex-1 flex justify-center flex-col items-center overflow-hidden relative">
                <Document
                  key={url}
                  className="select-none "
                  renderTextLayer={false}
                  file={context.filesServerUrl + url}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page
                    pageNumber={pageNumber}
                    renderAnnotationLayer={false}
                    canvasRef={canvasRef}
                    renderTextLayer={false}
                    className="max-w-[20rem] mt-3 mb-3"
                    onRenderSuccess={onPageRenderSuccess}
                    // className=" max-w-[20rem]"
                  />
                </Document>

                <div className="w-full p-4 flex justify-center items-center z-10">
                  <button
                    className="bg-blue-600 text-white px-6 py-8 rounded-md cursor-pointer z-10 w-full"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                    onClick={() => {
                      window.open(context.filesServerUrl + url, "_blank");
                    }}
                  >
                    <Icon
                      icon="mdi:external-link"
                      style={{ marginRight: "10px" }}
                    />
                    Open
                  </button>
                </div>

                <div className="text-sm text-gray-500 dark:text-gray-300">
                  Note: This is the original document that needs to be
                  translated. Change the switch to view the translated document.
                </div>
              </div>
            ) : (
              <div className="border border-dashed border-gray-400 rounded-md p-4 flex-1 flex justify-center flex-col items-center overflow-hidden relative">
                <p className="">No document uploaded</p>
              </div>
            )}

            <div className="flex justify-between items-center flex-wrap gap-4">
              {clientDetails?.is_super_admin == 1 ? (
                <div style={{ marginBottom: "10px", marginTop: "10px" }}>
                  <input
                    type="file"
                    id="upload"
                    style={{ display: "none" }}
                    accept="application/pdf"
                    onChange={handleFileChange}
                  />
                  <label
                    htmlFor="upload"
                    style={{
                      backgroundColor: "#7C3AED", // violet-600
                      color: "#FFFFFF", // white
                      padding: "0.5rem 0.75rem",
                      borderRadius: "0.375rem",
                      cursor: "pointer",
                      display: "inline-block" // to ensure padding is applied
                    }}
                  >
                    Upload Original Document
                  </label>
                </div>
              ) : null}
              {clientDetails?.is_translator == 1 ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.5rem",
                    marginBottom: "10px"
                  }}
                >
                  <input
                    type="file"
                    id="uploadTranslated"
                    style={{ display: "none" }}
                    accept="application/pdf"
                    disabled={!url && !isChecked}
                    onChange={handleFileChangeTranslated}
                  />
                  <label
                    htmlFor="uploadTranslated"
                    style={{
                      backgroundColor: "#0D9488", // teal-600
                      width: "fit-content",
                      color: "#FFFFFF", // white
                      padding: "0.5rem 0.75rem",
                      borderRadius: "0.375rem",
                      cursor: !url && !isChecked ? "not-allowed" : "pointer",
                      opacity: !url && !isChecked ? "0.5" : "1",
                      display: "inline-block" // ensures padding is applied like a button
                    }}
                  >
                    Upload Translated Document
                  </label>
                </div>
              ) : null}
              <div>
                <button
                  style={{
                    border: "1px solid #0D9488", // teal-600
                    color: "#0D9488", // teal-600 text color when active, lighter when disabled
                    padding: "0.5rem 0.75rem",
                    borderRadius: "0.375rem",
                    cursor: "pointer",
                    backgroundColor: "transparent", // slight background when disabled
                    opacity: "1",
                    transition: "all 0.3s"
                  }}
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  {clientDetails?.is_translator === 1
                    ? "View Document Details"
                    : "Order Translation"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Sheet key={side} open={open} onOpenChange={setOpen}>
          <SheetContent
            side={side}
            className="overflow-y-auto"
            // style={{ maxHeight: "70%" }}
          >
            <SheetHeader>
              <SheetTitle className="text-2xl font-bold text-gray-700 pt-8 text-transform: uppercase">
                {documentDetails?.document_name}
              </SheetTitle>
              <SheetDescription className="text-1xl text-gray-500 text-transform: capitalize">
                {documentDetails?.document_description}
              </SheetDescription>
            </SheetHeader>

            <div className="">
              {translationData && (
                <PriceDealTranslation
                  open={open}
                  setOpen={setOpen}
                  document_id={document_id}
                  student_id={student_id}
                  numPages={numPages}
                  document_category_id={document_category_id}
                />
              )}
            </div>
          </SheetContent>
        </Sheet>
      </div>
    </div>
  );
};

export default TranslateDocument;
