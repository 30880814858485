import React, { useState } from "react";
import MasterLayout from "../masterLayout/MasterLayout";
import Breadcrumb from "../components/Breadcrumb";
import TableDataLayer from "../components/TableDataLayer";

const StudentTranslationPage = () => {
  const [currentType, setCurrentType] = useState("students");
  return (
    <>
      <MasterLayout>
        <Breadcrumb title="Students Translation" />
        <TableDataLayer
          currentType={currentType}
          setCurrentType={setCurrentType}
        />
      </MasterLayout>
    </>
  );
};

export default StudentTranslationPage;
