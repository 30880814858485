import React, { useContext, useEffect, useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Link, useNavigate } from "react-router-dom";
import { GlobalInfo } from "../App";
import axios from "axios";
import moment from "moment";
import "datatables.net-dt/js/dataTables.dataTables.js";
import $ from "jquery";
import DocumentCard from "./child/DocumentCard";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  flexRender,
  getSortedRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";
import { Button } from "react-bootstrap";

const TableDataLayer = ({ currentType, setCurrentType }) => {
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const context = useContext(GlobalInfo);
  const [data, setData] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [renderDocuments, setRenderDocuments] = useState(false);
  const [columnVisibility, setColumnVisibility] = useState({});

  const [rowSelection, setRowSelection] = useState({});
  const navigate = useNavigate();

  const getStudents = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${context.apiEndPoint}admin/translator/students`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(response.data);
      console.log("====================================");
      console.log(response.data);
      console.log("====================================");
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const changeFunction = () => {
    context.setStudentData(true);
  };

  useEffect(() => {
    if (currentType === "students") {
      getStudents();
    }
  }, [currentType, renderDocuments]);

  useEffect(() => {
    if (data.length > 0) {
      const tableInstance = $("#dataTable").DataTable({
        pageLength: 10,
      });

      return () => {
        tableInstance.destroy(true);
      };
    }
  }, [data]);

  return (
    <>
      <div className="card basic-data-table">
        {currentType === "students" && (
          <>
            <div className="card-header">
              <h5 className="card-title mb-0">Students</h5>
              <p>List of students assigned to you</p>
            </div>

            <div className="card-body overflow-x-scroll no-scrollbar">
              <table
                className="table bordered-table mb-0"
                id="dataTable"
                data-page-length={10}
              >
                <thead>
                  <tr scope="col">
                    <th>Full Name</th>
                    <th>Paid Amount in EUR</th>
                    <th>Net Amount in EUR</th>
                    <th>Last Updated at</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {!loading && data.length > 0 ? (
                    data.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <Link
                            className="text-warning-500"
                            // target="_blank"
                            to={`/documents/translator/student/${item.student_id}`}
                          >
                            <div className="text-warning-700 font-semibold flex items-center">
                              {item.first_name} {item.last_name}
                            </div>
                          </Link>
                        </td>
                        <td>
                          <div>{item.total_paid_amt}</div>
                        </td>
                        <td>
                          <div>{item.total_net_amt}</div>
                        </td>
                        <td>
                          <div>
                            {moment(item.latest_modified_at * 1000).format(
                              "DD-MM-YYYY, HH:mm"
                            )}
                          </div>
                        </td>
                        <td>
                          <Link
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                            to={
                              "/documents/translator/student/" + item.student_id
                            }
                          >
                            <Icon
                              icon="bi:translate"
                              className="menu-icon"
                              color="var(--brand)"
                              style={{ fontSize: "22px" }}
                            />
                          </Link>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="10" className="text-center">
                        {loading ? "Loading data..." : "No data available"}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </>
        )}
        {currentType === "documents" && (
          <DocumentCard
            renderDocuments={renderDocuments}
            loading={loading}
            setLoading={setLoading}
          />
        )}
      </div>
    </>
  );
};

export default TableDataLayer;
